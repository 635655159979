<template>
  <loader-content v-if="loading" />

  <section v-else>
    <div class="d-flex align-center mb-6">
      <div class="text-h5 font-weight-bold mr-4">
        <span class="saira">Inscritos</span>
      </div>

      <div class="text-body-2 subtext--text">{{ teams.length }} inscritos</div>
    </div>

    <v-data-table
      v-if="teams.length > 0"
      class="transparent"
      :headers="headers"
      :items="teams"
      :items-per-page="pagination.itemsPerPage"
      hide-default-footer
      disable-sort
    >
      <template v-slot:[`item.index`]="{ index }"> {{ index + 1 }}</template>

      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip bottom open-delay="150" close-delay="150">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center" v-on="on" v-bind="attrs">
              <v-avatar
                class="pointer mr-3"
                size="32"
                rounded
                v-ripple
                @click="handleSelectTeam(item.id)"
              >
                <v-img :src="item.logo" contain />
              </v-avatar>

              <span
                class="pointer text-decoration-underline text--body-4"
                v-ripple
                v-text="item.name"
                @click="handleSelectTeam(item.id)"
              />
            </div>
          </template>
          <template v-slot:default>
            <div class="d-flex flex-column justify-center align-center">
              <div
                v-for="(player, index) of item.team_players"
                :key="index"
                class="d-flex align-center"
              >
                <v-icon
                  x-small
                  v-text="
                    item.userId === player.user.id
                      ? 'mdi-shield-account'
                      : 'mdi-account'
                  "
                />
                <span class="text--body-4 ms-1" v-text="player.user.nickname" />
              </div>
            </div>
          </template>
        </v-tooltip>
      </template>

      <template v-slot:[`item.participants`]="{ item }">
        {{ item.team_players.length }} / {{ selected.x }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip class="red" :color="getStatus(item).color" label>
          <span class="text--body-3 px-2" v-text="getStatus(item).name" />
        </v-chip>
      </template>
    </v-data-table>

    <div v-else class="d-flex justify-center align-center">
      <span class="text-body-1 subtext--text">
        Nenhum resultado foi encontrado
      </span>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getTeams } from "@/services/teams";

const moment = require("moment");

export default {
  name: "TeamsComponent",
  data() {
    return {
      loading: true,
      pagination: {
        page: 0,
        pageCount: 1,
        itemsPerPage: 20,
        total: 0,
      },
      headers: [
        {
          text: "#",
          value: "index",
        },
        {
          text: "Equipe",
          value: "name",
        },
        {
          text: "Participantes",
          value: "participants",
        },
        {
          text: "Inscrito em",
          value: "createdAt",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      teams: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    ...mapState(["selected"]),
  },

  methods: {
    async getData() {
      try {
        const payload = {
          leagueId: this.selected.id,
        };

        await getTeams(payload).then((res) => {
          this.teams = res;
        });
      } catch (err) {
        // err
      } finally {
        this.loading = false;
      }
    },

    getStatus(item) {
      const x = this.selected.x;
      const players = item.team_players.length;

      if (players == x) return { name: "Completo", color: "green2" };
      if (players < x) return { name: "Incompleto", color: "neutral5" };
      if (players > x) return { name: "Excesso", color: "error" };

      return {};
    },

    handleSelectTeam(id) {
      this.$router.push({ path: `/team-details/${id}` });
    },

    formatDate(value) {
      if (!value) return;
      return moment(value).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style></style>
